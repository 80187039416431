<template>
    <Switch
            v-model="enabled"
            :class="enabled ? 'bg-blue-600' : 'bg-gray-200'"
            class="relative inline-flex h-6 w-11 items-center rounded-full"
    >
        <span class="sr-only">Включить debug</span>
        <span
                :class="enabled ? 'translate-x-6' : 'translate-x-1'"
                class="inline-block h-4 w-4 transform rounded-full bg-white transition"
        />
    </Switch>
</template>

<script>
import {Switch} from '@headlessui/vue'
import {useApp} from "~/store/app/app";
import {usePatient} from "~/store/app/patient";


export default {
    data() {
        return {
            enabled: false
        }
    },
    setup() {
        const appStore = useApp();
        const patientStore = usePatient();
        return {
            appStore,
            patientStore
        }
    },
    watch: {
        enabled() {
            this.appStore.setDebug(this.enabled);
        }
    },
    components: {
        Switch
    }
}

</script>