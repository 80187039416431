<template>
    <div>
        <div class="my-8 max-w-xl">
            <AppUiBtn @click="appStore.open = true" text="Записаться"/>
        </div>


        <div class="text-xl cursor-pointer">
            Debug:
            <AppDebugOnOff/>
        </div>

        <Disclosure as="div" class="my-4">
            <DisclosureButton as="template">
                <div class="text-xl underline cursor-pointer">Врачи ({{ appStore.hotDocsList().length }})</div>
            </DisclosureButton>

            <DisclosurePanel as="div">
                <div v-if="appStore.hotDocsList().length">
                    <table>
                        <tr v-for="docId of appStore.hotDocsList()">
                            <td>{{ docStore.getDocName(docStore.getDocById(docId)) }}</td>
                            <td>от {{ docStore.getDocById(docId)?.attributes?.age_from }}</td>
                            <td>до {{ docStore.getDocById(docId)?.attributes?.age_to }}</td>
                        </tr>
                    </table>
                </div>
            </DisclosurePanel>
        </Disclosure>

        <Disclosure as="div" class="my-4">
            <DisclosureButton as="template">
                <div class="text-xl underline cursor-pointer">Активные группы прайса
                    ({{ appStore.hotListGroups.length }})
                </div>
            </DisclosureButton>

            <DisclosurePanel as="div">
                <div v-if="appStore.hotListGroups.length">
                    <div v-for="group of appStore.hotListGroups">

                        <a :href="`https://devadmin.fomin-kids.ru/admin/content-manager/collectionType/api::price-group.price-group/${group.id}`"
                           target="_blank"><b>{{ group?.attributes.title }}</b></a>
                        <div class="ms-8" v-for="price of group?.attributes?.prices?.data">
                            <a :href="`https://devadmin.fomin-kids.ru/admin/content-manager/collectionType/api::price.price/${price.id}`"
                               target="_blank">{{ price?.attributes.title }}</a>
                            {{ price?.attributes?.online ? 'online' : '' }}
                            {{ price?.attributes?.clinic ? 'clinic' : '' }}

                        </div>
                    </div>
                </div>
            </DisclosurePanel>
        </Disclosure>


        <Disclosure as="div" class="my-4">
            <DisclosureButton as="template">
                <div class="text-xl underline cursor-pointer">Активные услуги
                    ({{ appStore.hotListPrices?.length }})
                </div>
            </DisclosureButton>

            <DisclosurePanel as="div">
                <div v-if="appStore.hotListPrices.length">
                    <div v-for="priceId of appStore.hotListPrices">
                        {{ priceStore.getPriceById(priceId)?.attributes?.title }}
                    </div>
                </div>
            </DisclosurePanel>
        </Disclosure>
    </div>
</template>
<script>
import {useApp} from "~/store/app/app";
import {useNav} from "~/store/app/nav";
import {usePatient} from "~/store/app/patient";
import {useRegion} from "~/store/app/region";
import {useDocs} from "~/store/docs";
import {usePrices} from "~/store/price";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/vue'

export default {
    setup() {
        const appStore = useApp();
        const navStore = useNav();
        const patientStore = usePatient();
        const regionStore = useRegion();
        const docStore = useDocs();
        const priceStore = usePrices();

        return {
            appStore,
            navStore,
            patientStore,
            regionStore,
            docStore,
            priceStore
        }
    },
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
    }

}
</script>